export const GET_BASIC_QUALITY_REQUEST = "GET_BASIC_QUALITY_REQUEST";
export const GET_BASIC_QUALITY_SUCCESS = "GET_BASIC_QUALITY_SUCCESS";
export const GET_BASIC_QUALITY_FAILURE = "GET_BASIC_QUALITY_FAILURE";

export const GET_MEDIA_REQUEST = "GET_MEDIA_REQUEST";
export const GET_MEDIA_SUCCESS = "GET_MEDIA_SUCCESS";
export const GET_MEDIA_FAILURE = "GET_MEDIA_FAILURE";

export const VIEW_DETAILS_REQUEST = "VIEW_DETAILS_REQUEST";
export const VIEW_DETAILS_SUCCESS = "VIEW_DETAILS_SUCCESS";
export const VIEW_DETAILS_FAILURE = "VIEW_DETAILS_FAILURE";

export const GET_SETTINGS_REQUEST = "GET_SETTINGS_REQUEST";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_FAILURE = "GET_SETTINGS_FAILURE";

export const SAVE_SETTING_REQUEST = "SAVE_SETTING_REQUEST";
export const SAVE_SETTING_SUCCESS = "SAVE_SETTING_SUCCESS";
export const SAVE_SETTING_FAILURE = "SAVE_SETTING_FAILURE";

export const LIST_INSPECTION_POINT_RANDOM_SCAN_REQUEST =
  "LIST_INSPECTION_POINT_RANDOM_SCAN_REQUEST";
export const LIST_INSPECTION_POINT_RANDOM_SCAN_SUCCESS =
  "LIST_INSPECTION_POINT_RANDOM_SCAN_SUCCESS";
export const LIST_INSPECTION_POINT_RANDOM_SCAN_FAILURE =
  "LIST_INSPECTION_POINT_RANDOM_SCAN_FAILURE";

export const SUBMIT_INSPECTION_POINT_REQUEST =
  "SUBMIT_INSPECTION_POINT_REQUEST";
export const SUBMIT_INSPECTION_POINT_SUCCESS =
  "SUBMIT_INSPECTION_POINT_SUCCESS";
export const SUBMIT_INSPECTION_POINT_FAILURE =
  "SUBMIT_INSPECTION_POINT_FAILURE";
