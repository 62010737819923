import React, { lazy, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./app.scss";
import ProtectedRoutes from "./Middleware/ProtectedRoutes";
import Loading from "./Components/Loading/Loading";
import "react-datepicker/dist/react-datepicker.css";

const Login = lazy(() => import("./Pages/Login"));
const ManufacturingPacking = lazy(() => import("./Pages/ManufacturingPackage"));
const Settings = lazy(() => import("./Pages/Settings"));
const BasicQuantity = lazy(() => import("./Pages/BasicQuantity"));
const ViewMedia = lazy(() => import("./Pages/ViewMedia"));
const ViewDetails = lazy(() => import("./Pages/ViewDetails"));
const BasicAudit = lazy(() => import("./Pages/BasicAudit"));

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    element: <ProtectedRoutes />,
    children: [
      { path: "/", element: <ManufacturingPacking /> },
      { path: "/settings", element: <Settings /> },
      { path: "/basic-quantity", element: <BasicQuantity /> },
      { path: "/view-media/:id/:serialCode?", element: <ViewMedia /> },
      { path: "/view-details/:batchId", element: <ViewDetails /> },
      { path: "/basic-audit", element: <BasicAudit /> },
    ],
  },
]);

const App = () => {
  return (
    <Suspense
      fallback={
        <Loading color={"rgba(1, 18, 34, 1)"} size={42} height={true} />
      }
    >
      <RouterProvider
        router={router}
        future={{ v7_startTransition: true, v7_relativeSplatPath: true }}
      />
    </Suspense>
  );
};

export default App;
